var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.$_loading_state
    ? _c("div", { staticClass: "p-coffeeMap p-coffeeMap--preview" }, [
        _c(
          "div",
          { staticClass: "p-coffeeMap__myCoffeeMapWrapper" },
          [
            _c("MyCoffeeMap", {
              attrs: {
                "map-values": _vm.mapValues,
                "active-cell-id": _vm.activeCellId,
                color: _vm.color,
              },
            }),
          ],
          1
        ),
        _vm.product
          ? _c(
              "div",
              { staticClass: "c-section p-coffeeMap__product" },
              [
                _c("CoffeeCard", {
                  attrs: {
                    src: _vm.product.image_url,
                    title: _vm.product.name,
                    "title-clamp": 3,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }