<template>
  <div v-if="!$_loading_state" class="p-coffeeMap p-coffeeMap--preview">
    <div class="p-coffeeMap__myCoffeeMapWrapper">
      <MyCoffeeMap
        :map-values="mapValues"
        :active-cell-id="activeCellId"
        :color="color" />
    </div>

    <div v-if="product" class="c-section p-coffeeMap__product">
      <CoffeeCard
        :src="product.image_url"
        :title="product.name"
        :title-clamp="3" />
    </div>
  </div>
</template>

<script>
/**
 * NOTE:
 * - 感想登録後にマップ状態を確認するために遷移してくる画面
 * - クエリパラメータに `product_id` と `impression_id` が渡ってくる前提
 */

import { SystemDialogMessage } from '@/constants/enums';

const COLOR = {
  MIN: { HUE: 28, SATURATION: 100, LIGHTNESS: 93 },
  MAX: { HUE: 29, SATURATION: 100, LIGHTNESS: 50 },

  /** 味覚診断の結果のみでコーヒーマップを描画する場合に用いる最も濃い色 */
  MAX2: { HUE: 29, SATURATION: 100, LIGHTNESS: 86 }
};

export default {
  components: {
    MyCoffeeMap: () => import('@/components/mypage/coffeeMap/MyCoffeeMap')
  },

  data: () => ({
    mapValues: Array(36).fill({
      id: null,
      val: null,
      count: { like: 0, hate: 0 }
    }),

    /** @type {number | null} 1〜36 */
    activeCellId: null,
    product: null
  }),

  computed: {
    /**
     * @returns {string} 統合製品ID
     */
    productId() {
      return this.$route.query.product_id;
    },

    /**
     * @returns {string} 感想ID
     */
    impressionId() {
      return this.$route.query.impression_id;
    },

    /**
     * @returns {number} 感想登録した統合製品のマップ位置 1〜36
     */
    productCellId() {
      return Number(this.product?.map_position);
    },

    /**
     * 過去に「好き」の感想登録があるかどうか
     */
    hasLikeImpression() {
      return this.mapValues.findIndex((v) => v.count.like > 0) !== -1;
    },

    color() {
      // 味覚診断の結果のみでコーヒーマップを描画する場合は、描画色を変更する
      if (!this.hasLikeImpression) {
        return {
          MIN: COLOR.MIN,
          MAX: COLOR.MAX2
        };
      }

      return {
        MIN: COLOR.MIN,
        MAX: COLOR.MAX
      };
    }
  },

  async created() {
    await this.init();
    await this.$utilities.sleep();
    this.activateCell(this.productCellId);
    await this.$utilities.sleep();
    this.$router.push({
      path: `/mypage/coffee-history/${this.impressionId}`,
      query: this.$route.query
    });
  },

  methods: {
    /**
     * コーヒーマップ情報を取得
     */
    async _getCoffeeMap() {
      const { data } = await this.$repositories('coffeeMap').get();

      // コーヒーマップ
      this.mapValues = data.coffee_map.map((item) => ({
        id: item.map_position,
        val: item.val,
        count: {
          like: item.is_like,
          hate: item.is_hate
        }
      }));
    },

    /**
     * 統合製品詳細を取得
     */
    async _getProduct() {
      const { data } = await this.$repositories('products').getProduct(
        this.productId
      );
      this.product = data;
    },

    async init() {
      try {
        this.$_loading_start();

        await Promise.all([this._getCoffeeMap(), this._getProduct()]);
      } catch {
        window.location.href = this.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        this.$_loading_stop();
      }
    },

    /**
     * 指定セルをアクティベートする
     * @param {number} cellId セルID
     */
    activateCell(cellId) {
      this.activeCellId = cellId;
    },

    /**
     * 指定セルをディアクティベートする
     */
    deactivateCell() {
      this.activeCellId = null;
    }
  }
};
</script>
